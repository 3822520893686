@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: none !important;
  transition: background-color 1s ease;
  background-color: #FDF8ED;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

a {
  cursor: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sectionTwoBg {
  background-color: #151515;
  & .cursor {
    border: 1px solid white;
  }

  & .cursor2 {
    background-color: white;
  }
}

.cursor {
  border: 1px solid black;
  border-radius: 50%;
  background: transparent;
  position: fixed;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .1s;
  pointer-events: none;
  z-index: 1000;
}

.cursor2 {
  width: 10px;
  height: 10px;
  background-color: black;
  border-radius: 50%;
  position: fixed;
  margin: 26px;
  z-index: 1000;
  pointer-events: none;
  transition: 1s width, 1s height, 1s margin, 0.2s transform;
}

.cursorHover {
  transform: scale(1.5);
}

.cursor2Hover {
    transform: scale(6);
    pointer-events: none;
    mix-blend-mode: difference;
    background-color: white;
    opacity: 1;
    &:active {
        transform: scale(10);
    }
}

.slideUpAnimation {
    animation-delay: 1s;
    animation: slideup 1s cubic-bezier(0.65, 0, 0.35, 1) both;
}

@keyframes slideup {
    0% {
        opacity: 0;
        transform: translateY(100%);   
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}