.pageHolder {
    width: 100vw;
    height: 200vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;

    &>a {
        color: white;
        font-size: 20vh;
    }
}

.section {
	position: absolute;
	width: 100%;
	height: 100vh;
	letter-spacing: 4px;
	overflow: hidden;
    display: flex;
    left: 0;
    justify-content: center;
	clip: rect(0, auto, auto, 0);
    transition: background-color 1s;
	.fixed {
		overflow: hidden;
		position: fixed;

        & h1 {
            font-size: 5vh;
        }

		& .animatedLink {
            text-decoration: none;
            position: absolute;
            margin-left: 5px;
            &::after {
                content: "";
                width: 0%;
                background-color: white;
                height: 2px;
                display: block;
                position: relative;
                margin: 0 10%;
                margin-top: 3px;
                transition: .2s width, .2s left;
                left: 50%;
            }

            &:hover {
                &::after {
                    width: 80%;
                    left: 0;
                }
            }
        }
	}
	.white {
		color: #fff;
	}
}

@for $i from 1 through 10 {
    .section:nth-child(#{$i}) {
      @if($i==1) {
        background-color: #fff;
        color: #000;
        top: 0;
      }
      @else if($i==10) {
        background-color: #000;
        color: #fff;
        top: (100vh * ($i - 1));
      }
      @else {
        box-shadow: inset 0 1px 80px rgba(0, 0, 0, 0.14);
        color: #fff;
        top: (100vh * ($i - 1));
        &>.fixed {
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            display: flex;
            flex-direction: column;
        }
      }
      z-index: ($i);
    }
}

.bgBigText {
    font-size: 10vh;
    font-family: 'Montserrat', monospace;
    display: flex;
    align-items: center;
    text-align: left;
    &>div {
        color: #151515;
    }
    &>.img {
        height: 100vh;
        width: 30vw;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 800px) {
    .bgBigText {
        flex-direction: column;
        height: 100vh;
        &>div {
            color: #151515;
            text-align: center;
            margin: 15vw 0;
            & h1 {
                font-size: 6vh !important;
            }
            & h3 {
                font-size: 3vh !important;
                margin: 10px 20px;
                letter-spacing: 0.5vw !important;
            }

            & a {
                margin: 0 20px;
            }
        }
        &>.img {
            width: 100vw;
            margin-top: 0px;
            margin-bottom: -50vh;
        }
    }
}

.texts {
    & div {
        overflow: hidden;
        & h1 {
            white-space: nowrap;
            &:nth-child(1) {
                font-size: min(18vh, 10vw);
                font-weight: 500;
            }
            &:nth-child(2) {
                color: white;
            }
            margin: 0vh 0;
        }

        &>h3 {
            font-size: min(4vh, 2vw);
            font-weight: 400;
            margin-bottom: 0;
        }
    }

    &>a {
        color: #151515;
        font-size: 50px;
        margin-right: 3vw;
    }
}

.hoverZone {
    position: relative;
    width: 16vw;
    height: calc(100% - 4vh);
}

.sectionTwo {

    & .texts {
        & div {

            & h1 {
                font-size: min(18vh, 10vw);
            }
    
            &>h3 {
                
            }
        }
    
        & a {
            color: white;
            text-decoration: none;
        }
    }
}

.statusBar {
    background-color: white;
    height: 8px;
    border-radius: 40px;
    width: min(200px, 40vw);
    position: absolute;
    bottom: 30px;
    margin: 0 calc((100vw - min(200px, 40vw) - 30px)/2);
    left: 36%;
    z-index: 1000;
    &>.statusBarProgress {
        width: 50%;
        height: 100%;
        background-color: #ebdaaf;
        border-radius: 40px;

    }
}

#horizontalScrollingContainer {
    left: 0;
}

@keyframes slideup {
    0% {
        transform: translateY(min(18vh, 10vw));   
    }
    100% {
        transform: translateY(0);
    }
}

.scrollingContainer {
    height: 100vh;
    overflow: auto;
    &> div:nth-child(1) {
        //width: 530vw;
    }
}

.projectContainer {
    min-width: 100vw;
    height: 100vh;
    padding-right: 20vw;
    display: flex;
    align-items: center;
}

.demoImage {
    min-width: 40vw;
    height: 50vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90%;
}

.codecollab {
    background-position: right;
    background-repeat: no-repeat;
    & .texts {
        padding-right: 50px;
        width: 50vw;
        & div {
            text-align: left;
            & h1 {
                margin-bottom: 0;
                z-index: 1000;
            }
    
            &>h3 {
                font-size: min(2vh, 1.5vw);
                margin-top: 0;
                letter-spacing: 1px;
            }
        }
    
        & a {
            color: white;
            text-decoration: none;
        }
    }
}

.swiftinsg {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
    color: #151515;
    & .texts {
        padding-right: 50px;
        width: 60vw;
        & div {
            text-align: left;
            & h1 {
                color: #151515;
                margin-bottom: 0;
                z-index: 1000;
            }
    
            &>h3 {
                font-size: min(2vh, 1.5vw);
                margin-top: 0;
                letter-spacing: 1px;
            }
        }
    
        & a {
            color: white;
            text-decoration: none;
        }
    }
}

.drawingboard {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100vw 100vh;
    color: #151515;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & .texts {
        width: 60vw;
        margin-left: 20vw;
        & div {
            text-align: center;
            & h1 {
                color: #151515;
                margin-bottom: 0;
                z-index: 1000;
                font-size: 10vh;
                font-weight: bold;
                white-space: pre-wrap;
            }
    
            &>h3 {
                font-size: min(2vh, 1.5vw);
                margin-top: 0;
                letter-spacing: 1px;
            }
        }
    
        & a {
            color: white;
            text-decoration: none;
        }
    }
}